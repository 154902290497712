import React, {
  useEffect,
  useState,
  useCallback
} from 'react';
import { parse, isBefore, format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { useContext } from 'react';
import mosaicLogo from "../../assets/images/shapeLine.png";
import { MenuContext } from 'contexts/menu';
import {
  listExperiments,
  listCurrentTrays,
  updateExperiment,
  getLayerControlData,
  getGreenhouseCameraUrl,
  createGcode,
  listGcodes,
  updateTray,
  listActiveTraysAtPosition,
} from 'services/api';
import './styles.scss';
import { RiArrowLeftDoubleLine, RiArrowRightDoubleLine, RiCalendarScheduleLine, RiPlantLine, RiSearch2Line } from 'react-icons/ri';
import { RiDeleteBin7Line } from 'react-icons/ri'
import Text from 'components/Text';
import Button from 'components/Button';
import DataTable from 'components/Table';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedTrayLayers } from 'experimentSlice';
import { Tooltip } from 'react-tooltip';
import Input from 'components/Input';
import { formatDate, getRootVar } from 'utils/generalFunctions';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { selectedCompany, selectedGreenhouse } = useContext(MenuContext);
  const [activeExperiments, setActiveExperiments] = useState(null);
  const [isLoadingActiveExperiments, setIsLoadingActiveExperiments] = useState(true);
  const [selectedActiveExperiment, setSelectedActiveExperiment] = useState(null);
  const [currentTrays, setCurrentTrays] = useState([]);
  const [trays, setTrays] = useState([]);
  const [isLoadingCurrentTrays, setIsLoadingCurrentTrays] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [code, setCode] = useState('');
  const [openDeleteExperimentModal, setOpenDeleteExperimentModal] = useState(false);
  const [openMosaicOverwriteModal, setOpenMosaicOverwriteModal] = useState(false);
  const [repeatedCodeWritten, setRepeatedCodeWritten] = useState(false);
  const [deleteExperiment, setDeleteExperiment] = useState();
  const [addIdentificationCodeModal, setAddIdentificationCodeModal] = useState();
  const [identificationCode, setIdentificationCode] = useState('');
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [selectedActiveTrayLayers, setSelectedActiveTrayLayers] = useState([]);
  const [experimentName, setExperimentName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const theme = document.querySelector("body").getAttribute("data-theme");
  const { t } = useTranslation()

  const columns = [
    {
      key: 'start_date',
      name: <Text color="gray2" className="f-2 bold">Data de início</Text>,
      render: (entity) => (
        <Text color="gray2" className="f-2" autoTranslate={false}>
          {entity.start_date ? format(entity.start_date, 'dd/MM/yyyy') : '-'}
        </Text>
      )
    },
    {
      key: 'name',
      name: <Text color="gray2" className="f-2 bold">Nome</Text>,
      render: (entity) => (
        <div className='d-flex align-items-center gap-2'>
          <Text color="gray2" className="f-2" autoTranslate={false}>
            {entity.name ? entity.name : '-'}
          </Text>
          {entity.end_date && (
            <>
              <div data-tooltip-id="my-tooltip">
                <RiCalendarScheduleLine color={getRootVar("--secondary")} size="14" />
              </div>
              <Tooltip
                id="my-tooltip"
                style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                <div className='d-flex align-items-start flex-column'>
                  <Text className="tinyTxt bold" color="gray2">Data de finalização</Text>
                  <Text className="smallTxt">{formatDate(entity.end_date)}</Text>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )
    },
    {
      key: 'edit/remove',
      name: <Text color="gray2" className="f-2 bold">Editar/Remover</Text>,
      render: (entity) => (
        <div className='d-flex align-items-center gap-3'>
          <Button onClick={() => handleEditExperiment(entity)} className="primary small"><Text className="small white">Editar</Text></Button>
          <RiDeleteBin7Line style={{ cursor: "pointer" }} size={16} fill={theme === "dark" ? getRootVar("--gray2") : getRootVar("--gray3")} onClick={() => handleFinalizeActiveExperimentBtnClick(entity)} />
        </div>
      )
    }
  ]

  const updateActiveExperiments = useCallback(async (companyName, greenhouseName) => {
    setIsLoadingActiveExperiments(true);

    const data = {};
    try {
      const res = await listExperiments(
        companyName,
        greenhouseName,
        true,
      );

      for (const exp of res.data) {
        const end_date = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());
        const today = new Date();
        if (end_date && isBefore(end_date, today)) {
          continue;
        }

        const item = {
          ...exp,
          schedule: exp.schedule.sort(),
          start_date: parse(exp.exp_start_date, 'yyyyMMdd', new Date()),
          plant_date: exp.plant_date && parse(exp.plant_date, 'yyyyMMdd', new Date()),
          exp_type: exp.exp_type ? exp.exp_type : "germination_test"
        };
        data[exp.uuid] = item;
      }
    } catch (ex) {
      console.error('ex:', ex);
      return;
    }
    setActiveExperiments(data);

    setIsLoadingActiveExperiments(false);
  }, [setActiveExperiments]);


  useEffect(() => {
    if (selectedCompany && selectedGreenhouse) {
      updateActiveExperiments(selectedCompany.name, selectedGreenhouse.name);
    }
  }, [selectedCompany, selectedGreenhouse, updateActiveExperiments]);

  async function updateCurrentTrays() {
    setIsLoadingCurrentTrays(true);
    setIsLoadingMap(true);
    const currentTrays = [];
    try {
      const res = await listCurrentTrays(
        selectedCompany.name,
        selectedGreenhouse.name,
      );

      for (const tray of res.data) {
        if (!tray.geometry_map) {
          continue;
        }

        const experiment = activeExperiments && activeExperiments[tray.experiment_uuid];
        currentTrays.push({
          ...tray,
          geometry: tray.geometry_map,
          experiment: experiment,
        });
      }
    } catch (ex) {
      console.error('ex:', ex);
      return;
    }
    setCurrentTrays(currentTrays);

    setIsLoadingMap(false);
    setIsLoadingCurrentTrays(false);
  }

  useEffect(() => {
    if (!isLoadingActiveExperiments && selectedCompany && selectedGreenhouse) {
      updateCurrentTrays();
    }
  }, [
    selectedCompany,
    selectedGreenhouse,
    isLoadingActiveExperiments,
    activeExperiments,
    setCurrentTrays,
    setIsLoadingCurrentTrays
  ]);


  useEffect(() => {
    if (!selectedCompany || !selectedGreenhouse || isLoadingCurrentTrays) {
      return;
    }

    (async () => {
      setIsLoadingMap(true);
      document.global.createEvent("React#HomePage#greenhouseMap#getMosaics", async (e) => {
        // get the layer control data
        let mosaics = [];
        try {
          const res = await getLayerControlData(selectedCompany?.name, selectedGreenhouse?.name);
          mosaics = res.data.data;
        } catch (e) {
          console.error("Failed to get the layer control data");
          console.error(`Error: ${e}`);
          await Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: "Falha ao carregar o mapa! Entre em contato com o suporte!"
          });
        }
        document.global.greenhouseMap.data = {
          mosaics: mosaics,
        };

        // add mosaic control to map in event handler from "public/static/js/greenhouseMap.js"
        document.global.triggerEvent(document.global.events["React#HomePage#greenhouseMap#addMosaicControlToMap"]);
      });

      let res = await getGreenhouseCameraUrl();
      const cameraUrl = res.data.data;

      res = await getLayerControlData(selectedCompany?.name, selectedGreenhouse?.name);
      const layerControlData = res.data;

      document.global.greenhouseMap.data = {
        company: selectedCompany,
        greenhouse: selectedGreenhouse,
        currentTrays,
        layerControlData,
        cameraUrl,
      };

      if (document.global.greenhouseMap.instance) {
        // update map in event handler from "public/static/js/greenhouseMap.js"
        document.global.triggerEvent(document.global.events["React#HomePage#greenhouseMap#componentDidUpdate"]);
      } else {
        // create map in event handler from "public/static/js/greenhouseMap.js"
        setIsLoadingMap(false)
        document.global.triggerEvent(document.global.events["React#HomePage#greenhouseMap#componentDidMount"]);
      }
      setIsLoadingMap(false);
    })();

    return () => {
      // destroy map in event handler from "public/static/js/greenhouseMap.js"
      document.global.triggerEvent(document.global.events["React#HomePage#greenhouseMap#componentWillUnmount"])
    }

  }, [
    selectedCompany,
    selectedGreenhouse,
    isLoadingCurrentTrays,
    currentTrays,
    activeExperiments
  ]);

  const getTrays = async () => {
    try {
      const trays = await listActiveTraysAtPosition(selectedCompany?.name, selectedGreenhouse?.name)
      setTrays(trays.data)
    } catch (error) {
      console.error('Erro ao listar as trays', error);
    }
  }

  function updateSelectedActiveExperimentTrays() {
    const selectedActiveExperimentTrays = currentTrays.filter(tray =>
      tray.active && tray.experiment && tray.experiment.uuid === selectedActiveExperiment.uuid
    );

    document.global.greenhouseMap.data = {
      selectedActiveExperimentTrays,
    };

    document.global.triggerEvent(document.global.events["React#HomePage#greenhouseMap#updateSelectedActiveExperimentTrays"]);
  }

  useEffect(() => {
    if (selectedActiveExperiment) {
      updateSelectedActiveExperimentTrays();
    }

    if (selectedCompany && selectedGreenhouse) {
      getTrays()
    }

  }, [currentTrays, selectedActiveExperiment]);

  const handleCreateExperiment = async (event) => {
    const selectedTrayLayers = document?.global?.greenhouseMap?.currentTrayLayers.filter(layer =>
      layer.feature.properties.className === "tray-selected")

    const selectedActiveTrayLayers = document?.global?.greenhouseMap?.currentTrayLayers?.filter(layer =>
      layer.feature.properties.className === "exp-tray-selected")

    if (selectedTrayLayers.length > 0 && selectedActiveTrayLayers.length === 0) {
      dispatch(setSelectedTrayLayers(selectedTrayLayers.map(e => e.feature)));
      navigate(`/experiment/new`);
    } else if (selectedActiveTrayLayers.length > 0) {
      toast.warning(t("Selecione apenas bandejas inativas para criar um novo experimento."));
    } else {
      toast.warning(t("Selecione as bandejas antes de criar um novo experimento."));
    }
  }

  const handleEditExperiment = async (e) => {
    navigate(`/experiment/${e.uuid}`)
  }

  const handleCreateMosaic = async (e) => {
    const selectedGeometriesLayers = document.global.greenhouseMap.geometryLayers.filter(layer =>
      layer.feature.properties.className === "brench-or-geometry-selected"
    );

    const selectedBenchesLayers = document.global.greenhouseMap.benchesLayers.filter(layer =>
      layer.feature.properties.className === "brench-or-geometry-selected"
    );

    if (selectedGeometriesLayers.length > 0 || selectedBenchesLayers.length > 0) {
      // check if there is an active gcode and what is its status
      let data = null;
      try {
        const res = await listGcodes(
          selectedCompany.name,
          selectedGreenhouse.name,
          true, // active
          'mosaic', // scanType
          null, // scanStatus
          null, // startDate
          null, // endDate
          [ // attributes
            "uuid",
            "status",
            "s3_key",
            "scan_type",
            // "scan_time",
            // "scan_duration",
            // "expected_pictures_taken",
            // "pictures_taken",
            // "mosaic_uuid",
          ],
        );
        data = res.data.data;

      } catch (ex) {
        console.error('ex:', ex);
        toast.error(t("Falha ao verificar as solicitações de scan correntes!"))
        return;
      }
      if (data.filter(item => item.status === 'started').length) {
        toast.warning(t("Já existe um scan de mosaico em andamento."))
        return;
      } else if (data.filter(item => item.status === 'not_started').length) {
        return setOpenMosaicOverwriteModal(true)
      } else {
        const canReplaceNotStartedGcodes = true

        const gcode = {
          "is_selected_greenhouse": selectedGeometriesLayers && selectedGeometriesLayers.length,
          "selected_bench_names": selectedBenchesLayers && selectedBenchesLayers.map(layer => layer.feature.properties.name),
        }

        try {
          await createGcode(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            gcode && gcode,
            canReplaceNotStartedGcodes,
          )

          toast.success(t("Solicitação bem sucedida! Em breve o mosaico será gerado."))
          return
        } catch (ex) {
          console.error('ex:', ex);
          toast.error(t("Falha ao criar o mosaico"))
        }
      }
    } else {
      toast.warning(t("Selecione no mapa as mesas ou sistema para criar o novo mosaico."))
    }
  }

  const confirmOverwriteMosaic = async () => {
    const selectedGeometriesLayers = document.global.greenhouseMap.geometryLayers.filter(layer =>
      layer.feature.properties.className === "brench-or-geometry-selected"
    );

    const selectedBenchesLayers = document.global.greenhouseMap.benchesLayers.filter(layer =>
      layer.feature.properties.className === "brench-or-geometry-selected"
    );

    setOpenMosaicOverwriteModal(false)

    const canReplaceNotStartedGcodes = true

    // create gcode
    const gcode = {
      "is_selected_greenhouse": selectedGeometriesLayers && selectedGeometriesLayers.length,
      "selected_bench_names": selectedBenchesLayers && selectedBenchesLayers.map(layer => layer.feature.properties.name),
    }

    try {
      await createGcode(
        selectedCompany?.name,
        selectedGreenhouse?.name,
        gcode && gcode,
        canReplaceNotStartedGcodes,
      )

      toast.success(t("Solicitação bem sucedida! Em breve o mosaico será gerado."))
      return
    } catch (ex) {
      console.error('ex:', ex);
      toast.error(t("Falha ao criar o mosaico"))
    }
  }

  const handleCloseModal = (setValue) => {
    setValue(false)
  }

  const handleFinalizeActiveExperimentBtnClick = (e) => {
    setDeleteExperiment(e)
    setOpenDeleteExperimentModal(true)
  }

  const confirmDeleteExperimentModal = async () => {
    setOpenDeleteExperimentModal(true);
    try {
      await updateExperiment(selectedCompany.name, selectedGreenhouse.name, deleteExperiment.uuid, {
        "active": false
      });

      toast.success(t('Experimento deletado com sucesso'))
      updateActiveExperiments(selectedCompany.name, selectedGreenhouse.name);
      setOpenDeleteExperimentModal(false)

    } catch (ex) {
      console.error('ex:', ex);
      toast.error(t('Não foi possível deletar este experimento'))
      return;
    }
  }

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
    setToggleOpen(false)
  }

  const onChange = (value, setValue) => {
    setValue(value)
  }

  const handleAddIdentificationCode = () => {
    const selectedActiveTrayLayers = document?.global?.greenhouseMap?.currentTrayLayers?.filter(layer =>
      layer.feature.properties.className === "exp-tray-selected")

    const selectedTrayLayers = document?.global?.greenhouseMap?.currentTrayLayers?.filter(layer =>
      layer.feature.properties.className === "tray-selected")

    const name = selectedActiveTrayLayers[0]?.feature?.properties?.experiment?.name
    const traycode = selectedActiveTrayLayers[0]?.feature?.properties?.code

    const traysCodes = trays && trays.map(tray => tray.code)
    setRepeatedCodeWritten(traysCodes)


    setSelectedActiveTrayLayers(selectedActiveTrayLayers)

    if (selectedTrayLayers?.length === 0) {
      if (selectedActiveTrayLayers?.length === 1) {
        setExperimentName(name)
        setAddIdentificationCodeModal(true)
        setIdentificationCode(traycode)
        setCode(traycode)
      } else if (selectedActiveTrayLayers?.length === 0) {
        toast.warning(t("Selecione pelo menos uma bandeja"))
      } else {
        toast.warning(t("Selecione apenas uma bandeja por vez"))
      }
    } else if (selectedTrayLayers?.length === 0 && selectedActiveTrayLayers?.length === 0) {
      toast.warning(t("Selecione pelo menos uma bandeja"))
    } else {
      toast.warning(t("Selecione apenas bandejas ativas"))
    }
  }

  const confirmAddIdentificationCode = async (code) => {
    const selectedActiveTrayLayer = selectedActiveTrayLayers[0]?.feature?.properties?.uuid;
    const thisTray = trays && trays.find(tray => tray.uuid === selectedActiveTrayLayer);

    try {
      const tray = {
        "code": code,
        "uuid": thisTray?.uuid,
      };

      const res = await updateTray(
        selectedCompany?.name,
        selectedGreenhouse?.name,
        tray?.uuid,
        tray
      );

      updateCurrentTrays()

      if (identificationCode) {
        toast.success(t("Código de identificação alterado!"));
      } else {
        toast.success(t("Código de identificação adicionado!"));
      }

      setTimeout(() => {
        setAddIdentificationCodeModal(false);
      }, isLoadingMap)

      if (res.status !== 200) {
        toast.warning(res.data.message);
        throw res;
      }

    } catch (ex) {
      console.error('ex:', ex);
      if (identificationCode) {
        toast.error(t("Falha ao alterar código de identificação"));
      } else {
        toast.error(t("Falha ao adicionar código de identificação"));
      }
      return;
    }
  };

  const handleCloseIdentificationCodeModal = () => {
    setAddIdentificationCodeModal(false)
    setIdentificationCode("")
    setCode("")
  }

  return (
    <>
      <div className="HomePage">
        {isLoadingMap ? (
          <div className='SidebarFloatIdCode'>
            <div className="Spinner"></div>
          </div>
        ) : (
          <div onClick={() => handleAddIdentificationCode()} className='addIdentificationCode'>
            <RiPlantLine color="white" size="18" />
          </div>
        )}
        {selectedGreenhouse && selectedCompany &&
          <div className='systemInfos'>
            <div className="info">
              <Text className="bold tinyTxt" color="secondary">Sistema</Text>
              <Text className="white smallTxt">{selectedGreenhouse?.name}</Text>
            </div>
            <div className="info">
              <Text className="bold tinyTxt" color="secondary">Empresa</Text>
              <Text className="white smallTxt">{selectedCompany?.name}</Text>
            </div>
          </div>
        }
        {sidebarOpen ? (
          <div className="Sidebar" style={{
            backgroundColor: theme === "dark" ? getRootVar("--altWhite") : getRootVar("--white"),
            border: theme === "dark" ? "none" : `1px solid ${getRootVar("--altWhite")}`
          }}>
            <div className='SidebarHeader'>
              <div onClick={() => setSidebarOpen(false)} className="d-flex align-items-center gap-2">
                <RiArrowRightDoubleLine color={getRootVar("--gray3")} size="24" />
                <Text className="bold">Experimentos ativos</Text>
              </div>
              {window.innerWidth > 980 ? (
                <div className="d-flex align-items-center gap-2">
                  <Button data-tooltip-id="mosaicTooltip" onClick={() => handleCreateMosaic()} icon className="secondary">
                    <img
                      src={mosaicLogo}
                      alt="Criar mosaico"
                      style={{ width: '16px', height: 'auto' }}
                    />
                  </Button>
                  <Button onClick={(e) => handleCreateExperiment(e)} className="primary"><Text className="bold white">Novo experimento</Text></Button>
                  <Tooltip
                    id="mosaicTooltip"
                    style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                    <div className='d-flex align-items-start flex-column'>
                      <Text className="tinyTxt bold" color="gray2">Gerar mosaico</Text>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div className="BtnsGroup">
                  <Button onClick={() => handleCreateMosaic()} className="secondary fluid">
                    <img
                      src={mosaicLogo}
                      alt="Criar mosaico"
                      style={{ width: '16px', height: 'auto' }}
                    />
                    <Text className="bold white">Gerar mosaico</Text>
                  </Button>
                  <Button onClick={(e) => handleCreateExperiment(e)} className="primary fluid"><Text className="bold white">Novo experimento</Text></Button>
                </div>
              )}
            </div>
            < div className="SidebarTable">
              <DataTable
                data={activeExperiments && Object.entries(activeExperiments)}
                noDataMessage={
                  <div className="noExperimentData">
                    <RiSearch2Line size="60" color={getRootVar("--gray2")} />
                    <div className='noExperimentMessage'>
                      <Text className="bold">Nenhum experimento encontrado!</Text>
                      <Text className="smallTxt">Crie um novo experimento para ele aparecer aqui.</Text>
                    </div>
                  </div>
                }
                columns={columns}
                filters={false}
                secondIndex
              />
            </div>
          </div>
        ) : (
          window.innerWidth > 980 ? (
            toggleOpen ? (
              <div className='SidebarFloatOpen' onClick={() => handleOpenSidebar()} onMouseLeave={() => setToggleOpen(false)}>
                <RiArrowLeftDoubleLine color="white" size="24" />
                <Text className="white bold">Experimentos ativos</Text>
              </div>
            ) : (
              !isLoadingMap ? (
                <div className='SidebarFloat' onMouseEnter={() => setToggleOpen(true)}>
                  <RiArrowLeftDoubleLine color="white" size="24" />
                </div>
              ) : (
                <div className='SidebarFloat'>
                  <div className="Spinner"></div>
                </div>
              )
            )
          ) :
            !isLoadingMap ? (
              <>
                <div className='SidebarFloatOpen' onClick={() => handleOpenSidebar()} onMouseLeave={() => setToggleOpen(false)}>
                  <Text className="white bold">Experimentos ativos</Text>
                </div>
                <div onClick={() => handleAddIdentificationCode()} className='addIdentificationCode'>
                  <RiPlantLine color="white" size="18" />
                </div>
              </>
            ) : (
              <>
                <div className='SidebarFloat'>
                  <div className="Spinner"></div>
                </div>
                <div className='SidebarFloatIdCode'>
                  <div className="Spinner"></div>
                </div>
              </>
            ))}
        <div className={isLoadingMap || isLoadingCurrentTrays ? "loadingMap" : "mapLoaded"}>
          <div
            id="greenhouseMap"
            className="greenhouseMap"
            style={isLoadingMap || isLoadingCurrentTrays ? { display: "hidden" } : { display: "auto" }}
          />
        </div>
      </div>
      {openDeleteExperimentModal && (
        <Modal
          title="Deletar experimento"
          confirm="Deletar experimento"
          dangerModal
          handleCloseModal={() => handleCloseModal(setOpenDeleteExperimentModal)}
          confirmModal={confirmDeleteExperimentModal}
          isLoading={isLoadingCurrentTrays}>
          <Text>Você tem certeza que deseja deletar esse experimento? Ele não será mais exibido na lista e no mapa.</Text>
        </Modal>
      )}
      {
        openMosaicOverwriteModal && (
          <Modal
            title="Gerar nova solicitação de scan de mosaico?"
            confirm="Gerar nova solicitação"
            handleCloseModal={() => handleCloseModal(setOpenMosaicOverwriteModal)}
            confirmModal={() => confirmOverwriteMosaic()}
            isLoading={isLoadingMap}>
            <Text>Esta operação sobrescreve a solicitação anterior e é irreversível!</Text>
          </Modal>
        )
      }
      {addIdentificationCodeModal && (
        identificationCode ? (
          <Modal
            title="Editar código de identificação"
            confirm="Editar código"
            handleCloseModal={() => handleCloseIdentificationCodeModal()}
            confirmModal={() => confirmAddIdentificationCode(code)}
            disabled={!code || repeatedCodeWritten.find(trayCode => trayCode === code)}
            isLoading={isLoadingMap}>
            <div className='d-flex flex-column gap-3'>
              <Text className="smallTxt" autoTranslate="false">
                {t('Você está editando um código em uma bandeja que faz parte do experimento "{{experimentName}}".', { experimentName })}
              </Text>
              <Input
                label={<Text className="bold smallTxt fluid">Código de identificação</Text>}
                placeholder="Digite o código de identificação"
                value={code}
                onChange={(e) => onChange(e.target.value, setCode)}
                style={{ maxWidth: "100%" }}
              />
              {code && repeatedCodeWritten.find(trayCode => trayCode === code) && (
                <Text className="tinyTxt" color="red" autoTranslate="false">
                  {t(
                    'Você já possui uma bandeja dentro do experimento "{{experimentName}}" com o código de identificação "{{code}}".',
                    { experimentName, code }
                  )}
                </Text>
              )}
            </div>
          </Modal>
        ) : (
          <Modal
            title="Adicionar código de identificação"
            confirm="Adicionar código"
            handleCloseModal={() => handleCloseIdentificationCodeModal()}
            confirmModal={() => confirmAddIdentificationCode(code)}
            disabled={!code || repeatedCodeWritten.find(trayCode => trayCode === code)}
            isLoading={isLoadingMap}>
            <div className='d-flex flex-column gap-3'>
              <Text className="smallTxt" autoTranslate="false">
                {t('Você está adicionando um código em uma bandeja que faz parte do experimento "{{experimentName}}".', { experimentName })}
              </Text>
              <Input
                label={<Text className="bold smallTxt fluid">Código de identificação</Text>}
                placeholder="Digite o código de identificação"
                value={code}
                onChange={(e) => onChange(e.target.value, setCode)}
                style={{ maxWidth: "100%" }}
              />
              {code && repeatedCodeWritten.find(trayCode => trayCode === code) && (
                <Text className="tinyTxt" color="red" autoTranslate="false">
                  {t(
                    'Você já possui uma bandeja dentro do experimento "{{experimentName}}" com o código de identificação "{{code}}".',
                    { experimentName, code }
                  )}
                </Text>
              )}
            </div>
          </Modal >
        )
      )}
    </>
  );
}
export default HomePage;