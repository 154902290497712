import { useState, forwardRef, useRef, useEffect } from 'react'
import './styles.scss'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6'
import { getRootVar } from 'utils/generalFunctions'
import { useTranslation } from 'react-i18next'
import Text from 'components/Text'
import { RiInformation2Line, RiTimeLine } from 'react-icons/ri'
import { Tooltip } from 'react-tooltip'

export const Input = forwardRef(
  (
    {
      className,
      type,
      label,
      bootstrapClass,
      placeholder,
      style,
      value,
      width,
      height,
      minWidth,
      password,
      onChange,
      required,
      disabled,
      small,
      info,
      time,
      search,
      tooltipInfoId,
      error,
      ...props
    },
    ref
  ) => {
    const theme = document.querySelector("body").getAttribute("data-theme");
    const [show, setShow] = useState(false)
    const [inputFocus, setInputFocus] = useState(false);
    const inputPassword = useRef(null);
    const { t } = useTranslation()

    const handleClickItem = () => {
      setInputFocus(true);
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (inputPassword.current && !inputPassword.current.contains(event.target)) {
          setInputFocus(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleClick = () => setShow(!show)

    return (
      <div
        className="label-title"
        style={{
          ...style,
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? ".6" : "1"
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {label &&
              <div className="d-flex gap-1">
                <div>{label}</div>
                {required && <Text className="smallTxt" color="red">*</Text>}
              </div>
            }
            {info && (
              <>
                <div data-tooltip-id={tooltipInfoId}>
                  <RiInformation2Line color={getRootVar("--gray3")} size="14" />
                </div>
                <Tooltip
                  id={tooltipInfoId}
                  style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                  {info}
                </Tooltip>
              </>
            )}
          </div>
          {time && (
            <>
              <div data-tooltip-id="time-tooltip" className="d-flex align-items-center gap-1">
                <RiTimeLine color={getRootVar("--gray3")} size="14" />
                <Text className="tinyTxt" color="gray3">{time}</Text>
              </div>
              <Tooltip
                id="time-tooltip"
                style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                <Text className="tinyTxt">Você tem {time} para editar essa informação. Após esse período, o campo será bloqueado.</Text>
              </Tooltip>
            </>
          )}
        </div>
        {password ? (
          <div ref={inputPassword} onMouseDown={handleClickItem} className={`PasswordInputWrapper ${small && "PasswordInputWrapperSmall"} ${inputFocus ? "inputActive" : ""}`}>
            <input
              type={show === true ? 'text' : 'password'}
              onChange={onChange}
              value={value}
              placeholder={t(placeholder)}
              className="PasswordInput"
              style={{
                width: width,
                height: height,
                backgroundColor: theme === "dark" && getRootVar("--altWhite"),
                border: `1px solid ${theme === "light" ? getRootVar("--gray4") : getRootVar("--altWhite")}`,
                minWidth: minWidth,
                marginBottom: error ? "3px" : ""
              }}
            />
            <div onClick={handleClick} className='d-flex' style={{ cursor: 'pointer' }}>
              {show === true ? (
                <FaRegEye color={getRootVar("--gray3")} style={{ marginLeft: '-18px' }} />
              ) : (
                <FaRegEyeSlash
                  color={getRootVar("--gray3")}
                  style={{ marginLeft: '-18px' }}
                />
              )}
            </div>
          </div>
        ) : (
          <input
            type="text"
            style={{
              width: width,
              height: height,
              backgroundColor: theme === "dark" && getRootVar("--altWhite"),
              border: `1px solid ${theme === "light" ? getRootVar("--gray4") : getRootVar("--altWhite")}`,
              minWidth: minWidth,
              marginBottom: error ? "3px" : ""
            }}
            ref={ref}
            disabled={disabled}
            {...props}
            className={`input ${small ? "inputSmall" : ""}`}
            value={value}
            onChange={onChange}
            placeholder={t(placeholder)}
          />
        )}
        {error && <Text className="tinyTxt" color="red">{error}</Text>}
      </div>
    )
  }
)

export default Input
