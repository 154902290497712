import React, { useState, useEffect, useRef } from 'react';
import "./index.scss";
import Text from 'components/Text';
import { RiArrowDownSLine, RiArrowUpSLine, RiSearchLine } from "react-icons/ri";
import { getRootVar } from 'utils/generalFunctions';

export default function Dropdown({
  items,
  selectedItem,
  onClickItem,
  title,
  placeholder
}) {
  const [input, setInput] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const filteredItems = input.length > 0
    ? items.filter(e =>
      e.name?.toLowerCase().replace(/[\s-]/g, '').includes(input.replace(/[\s-]/g, '').toLowerCase())
    )
    : items;

  const handleClickItem = (item) => {
    if (onClickItem) {
      onClickItem(item);
      setDropdownOpen(false); // Fecha o dropdown ao selecionar
    }
  };

  // Fecha o dropdown ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="Dropdown" ref={dropdownRef}>
      <div className="DropdownToggle" onClick={() => setDropdownOpen(!dropdownOpen)}>
        <Text>{title}</Text>
        <Text className="dropdownSelectText">{selectedItem ? selectedItem.name : "Selecionar"}</Text>
        {dropdownOpen ? (
          <RiArrowUpSLine color={getRootVar("--gray3")} />
        ) : (
          <RiArrowDownSLine color={getRootVar("--gray3")} />
        )}
      </div>
      {dropdownOpen && (
        <div className="DropdownBody">
          <div className="DropdownHeader">
            <div className="SearchBar">
              <input
                type="text"
                onChange={(e) => setInput(e.target.value)}
                value={input}
                placeholder={placeholder}
                className="SearchInput"
              />
              <RiSearchLine color={getRootVar("--gray3")} />
            </div>
          </div>
          <div className={`DropdownContent ${filteredItems.length < 3 && "DropdownContentNoScroll"}`}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <div
                  className={`DropdownItem ${selectedItem?.name === item.name ? "DropdownItemActive" : ""}`}
                  id={item.name}
                  key={item.name}
                  onClick={() => handleClickItem(item)}
                >
                  <Text className="smallTxt">{item.name}</Text>
                </div>
              ))
            ) : (
              <Text className="NoResults">Nenhum resultado encontrado.</Text>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
