import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from 'contexts/auth';
import './styles.scss';
import logo from '../../assets/images/pverde.png'
import largeLogo from '../../assets/images/phenosync.png'
import Text from 'components/Text';
import Input from 'components/Input';
import { getRootVar, onChange } from 'utils/generalFunctions';
import Button from 'components/Button';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const RegisterPage = () => {
  const { navigate } = useContext(AuthContext);
  const [name, setName] = useState("")
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [accessKey, setAccessKey] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [error, setError] = useState("");
  const [register, setRegister] = useState(false);
  const { t } = useTranslation

  const validateEmail = (value) => {
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setError(t("E-mail inválido. Certifique-se de incluir '@' e '.com'."));
    } else {
      setError("");
    }
  };

  const validatePassword = () => {
    if (confirmPassword.length >= password.length) {
      if (confirmPassword !== password) {
        setPasswordError("As senhas não coincidem.");
      } else {
        setPasswordError("");
      }
    }
  }

  useEffect(() => {
    validatePassword()
  }, [password, confirmPassword]);

  function handleLogin() {
    setRegister(true);
    navigate("/login");
  }

  const canNotRegister = name.length === 0 || username.length === 0 || email.length === 0 || accessKey.length === 0 || password.length === 0 || confirmPassword.length === 0 || confirmPassword !== password

  return (
    <div className="RegisterContainer">
      <div className="Wrapper">
        {window.innerWidth > 980 &&
          <div className="Content gap-4">
            <div className='d-flex gap-4 flex-column'>
              <img
                src={logo}
                alt="Phenosync"
                style={{ width: '70px', height: 'auto' }}
              />
              <Text className="bold" style={{ fontSize: "48px", maxWidth: "300px", lineHeight: "56px" }}>Onde pesquisa e futuro se conectam.</Text>
            </div>
            <img
              src={largeLogo}
              alt="Phenosync"
              style={{ width: '100px', height: 'auto' }}
            />
          </div>
        }
        <div className="Form">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex align-items-center justify-content-center w-100">
              <Text color="secondary" className="bold" style={{ fontSize: "24px" }}>Faça seu cadastro</Text>
            </div>
            <div className="ColumnsWrapper">
              <div className="SubColumns">
                <Input
                  label={<Text className="bold smallTxt">Nome completo</Text>}
                  placeholder="Digite seu nome completo"
                  value={name}
                  onChange={(e) => onChange(e.target.value, setName)}
                  required
                />
                <Input
                  label={<Text className="bold smallTxt">Nome de usuário</Text>}
                  placeholder="Digite seu nome de usuário"
                  value={username}
                  onChange={(e) => onChange(e.target.value, setUsername)}
                  required
                />
                <Input
                  label={<Text className="bold smallTxt">E-mail</Text>}
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                  required
                  error={register && error}
                />
              </div>
              <div className="SubColumns">
                <Input
                  label={<Text className="bold smallTxt">Chave de acesso</Text>}
                  placeholder="Digite sua chave de acesso"
                  value={accessKey}
                  onChange={(e) => onChange(e.target.value, setAccessKey)}
                  required
                />
                <Input
                  label={<Text className="bold smallTxt">Senha</Text>}
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={(e) => onChange(e.target.value, setPassword)}
                  required
                  password
                />
                <Input
                  label={<Text className="bold smallTxt">Confirmar senha</Text>}
                  placeholder="Confirme sua senha"
                  value={confirmPassword}
                  onChange={(e) => onChange(e.target.value, setConfirmPassword)}
                  required
                  password
                  error={passwordError}
                />
              </div>
            </div>
          </div>
          <div data-tooltip-id="registerTooltip">
            <Button disabled={canNotRegister} onClick={handleLogin} className="primary fluid">
              <Text className="bold">Fazer cadastro</Text>
            </Button>
          </div>
          {canNotRegister &&
            <Tooltip
              id="registerTooltip"
              style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
              <div className='d-flex align-items-start flex-column'>
                <Text className="smallTxt">Preencha todos os campos acima</Text>
              </div>
            </Tooltip>
          }
          <div className="d-flex gap-1 w-100 justify-content-center align-items-center">
            <Text className="smallTxt">Já possui uma conta?</Text>
            <Text color="secondary" className="smallTxt bold" onClick={() => navigate("/login")}>Entrar</Text>
          </div>
        </div>
      </div>
      {window.innerWidth <= 980 &&
        <img
          src={largeLogo}
          alt="Phenosync"
          style={{ width: '110px', height: 'auto' }}
        />
      }
    </div>
  )
}

export default RegisterPage;