import React, { useEffect, useRef, useState } from 'react';
import { LuCalendar } from 'react-icons/lu';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { English } from 'flatpickr/dist/l10n/default';
import { Spanish } from 'flatpickr/dist/l10n/es';
import './styles.scss';
import { formatDate, getRootVar } from 'utils/generalFunctions';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import { RiCloseLine } from 'react-icons/ri';

export const DatePicker = ({
  options,
  onChange,
  label,
  fluid,
  placeholder,
  minDate,
  maxDate,
  value,
  disabled,
  required,
  mode = 'single',
  small,
  clear
}) => {
  const datePickerRef = useRef(null);
  const flatpickrInstance = useRef(null);
  const theme = document.querySelector("body").getAttribute("data-theme");
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  const { t } = useTranslation();
  const [displayValue, setDisplayValue] = useState('');

  const getRangeText = () => {
    return selectedLanguage === "en-US" ? 'to' : 'até';
  };

  const formatDisplayValue = (dates) => {
    if (mode === 'range' && dates.length > 1) {
      return `${formatDate(dates[0])} ${getRangeText()} ${formatDate(dates[1])}`;
    } else if (mode === 'multiple') {
      return dates.map(date => formatDate(date)).join(', ');
    } else if (dates.length === 1) { // Modo 'single'
      return formatDate(dates[0]);
    }
    return '';
  };

  useEffect(() => {
    setDisplayValue(formatDisplayValue(value ? (Array.isArray(value) ? value : [value]) : []));
  }, [value, mode]);

  useEffect(() => {
    if (!datePickerRef.current) return;

    flatpickrInstance.current = flatpickr(datePickerRef.current, {
      dateFormat: selectedLanguage === "en-US" ? 'm/d/Y' : 'd/m/Y',
      locale: selectedLanguage === "en-US" ? English : selectedLanguage === "es-ES" ? Spanish : Portuguese,
      disableMobile: true,
      minDate: minDate,
      maxDate: maxDate,
      ...options,
      defaultDate: value,
      disabled: disabled,
      mode: mode,
      onChange: (selectedDates) => {
        if (onChange) {
          const formattedDisplayValue = formatDisplayValue(selectedDates);
          setDisplayValue(formattedDisplayValue);
          onChange(mode === 'multiple' || mode === 'range' ? selectedDates : selectedDates[0]);
        }
      }
    });

    flatpickrInstance.current.calendarContainer?.classList.add('flatpickr-light');

    return () => {
      flatpickrInstance.current?.destroy();
      flatpickrInstance.current = null;
    };
  }, [selectedLanguage, minDate, maxDate, disabled, options, mode]);

  useEffect(() => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.setDate(value, false);
    }
  }, [value]);

  return (
    <div
      className='label-title'
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? ".6" : "1"
      }}
    >
      {label &&
        <div className="d-flex gap-1">
          <div>{label}</div>
          {required && <Text className="smallTxt" color="red">*</Text>}
        </div>
      }
      <div className="datepickerWrapper">
        <input
          type="text"
          style={{
            backgroundColor: theme === "dark" ? getRootVar("--altWhite") : '',
            border: `1px solid ${theme === "light" ? getRootVar("--gray4") : getRootVar("--altWhite")}`
          }}
          ref={datePickerRef}
          className={small ? "inputSmall" : "input"}
          value={displayValue}
          placeholder={t(placeholder)}
          disabled={disabled}
          readOnly
        />
        {clear && displayValue &&
          <div className="placeholder-icon close" onClick={() => { setDisplayValue(''); clear(); }}>
            <RiCloseLine color={getRootVar("--gray3")} size={20} />
          </div>}
        <div className="placeholder-icon">
          <LuCalendar color={getRootVar("--gray3")} />
        </div>
      </div>
    </div>
  );
}

export default DatePicker;
