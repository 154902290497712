import React, { useContext } from 'react';
import './styles.scss';
import ThemeMode from 'components/ThemeMode';
import Text from 'components/Text';
import SelectLanguage from 'components/SelectedLanguage';
import Button from 'components/Button';
import { AuthContext } from 'contexts/auth';

const Settings = () => {
  const { logout } = useContext(AuthContext);

  return (
    <>
      <div className="Settings">
        <Text className="bold big gray2">Configurações</Text>
        <div className='Selects'>
          <ThemeMode />
          <SelectLanguage />
        </div>
        <div className='Logout'>
          <Text className="bold medium">Sair do sistema</Text>
          <Button className="red fluid" onClick={logout}>
            <Text className="bold white">Sair do sistema</Text>
          </Button>
        </div>
      </div>
    </>
  )
}
export default Settings;