import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from 'contexts/auth';
import './styles.scss';
import logo from '../../assets/images/pverde.png'
import largeLogo from '../../assets/images/phenosync.png'
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { getRootVar } from 'utils/generalFunctions';
import VerificationInput from 'react-verification-input'
const RecoverPasswordPage = () => {
  const { navigate } = useContext(AuthContext);
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [sendToken, setSendToken] = useState(false)
  const [resendToken, setResendToken] = useState(false)
  const [resendDelayTime, setResendDelayTime] = useState(0)
  const [token, setToken] = useState(false)
  const [errorVerificationToken, setErrorVerificationToken] = useState(0)
  const [registerNewPassword, setRegisterNewPassword] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [isLoadingRegisterNewPassword, setIsLoadingRegisterNewPassword] = useState()

  const { t } = useTranslation()
  const tokenSentToEmailTest = "123456"

  function handleSendToken() {
    setSendToken(true);
  }

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(t("E-mail inválido. Certifique-se de incluir '@' e '.com'."));
    } else {
      setError("");
      handleSendToken()
    }
  };

  const resendVerificationToken = () => {
    setToken("")
    setResendToken(false)
    let time = 59
    setResendDelayTime(time)
    const interval = setInterval(() => {
      time--
      setResendDelayTime(time)
      if (time === 0) {
        clearInterval(interval)
        setResendToken(true)
      }
    }, 1000)
  }

  const handleVerifyToken = (completedToken) => {
    if (completedToken === tokenSentToEmailTest) {
      setErrorVerificationToken(2);
    } else {
      setErrorVerificationToken(1);
    }
  };

  const handleRegisterNewPassword = () => {
    setIsLoadingRegisterNewPassword(true)
    setTimeout(() => {
      setIsLoadingRegisterNewPassword(false)
      navigate("/login")
    }, 1000)
  };

  useEffect(() => {
    if (sendToken) {
      resendVerificationToken()
    }
  }, [sendToken])

  useEffect(() => {
    if (errorVerificationToken === 2) {
      setRegisterNewPassword(true)
    }
  }, [errorVerificationToken])

  useEffect(() => {
    if (resendDelayTime === 1) {
      setTimeout(() => {
        setResendToken(true)
      }, 1000)
    }
  }, [resendDelayTime])

  return (
    <div className="RecoverPasswordCointainer">
      <div className="Wrapper">
        {window.innerWidth > 980 &&
          <div className="Content gap-4">
            <div className='d-flex gap-4 flex-column'>
              <img
                src={logo}
                alt="Phenosync"
                style={{ width: '70px', height: 'auto' }}
              />
              <Text className="bold" style={{ fontSize: "48px", maxWidth: "300px", lineHeight: "56px" }}>Onde pesquisa e futuro se conectam.</Text>
            </div>
            <img
              src={largeLogo}
              alt="Phenosync"
              style={{ width: '100px', height: 'auto' }}
            />
          </div>
        }
        {registerNewPassword ? (
          <div className="Form">
            <div className="d-flex flex-column gap-4">
              <div className="d-flex align-items-center justify-content-center w-100 position-relative">
                <RiArrowLeftSLine onClick={() => navigate("/login")} style={{ position: "absolute", left: 0 }} size="24" color={getRootVar("--gray3")} />
                <Text color="secondary" className="bold" style={{ fontSize: "24px" }}>Cadastrar senha</Text>
              </div>
              <div className="d-flex gap-4 flex-column">
                <Input
                  label={<Text className="bold smallTxt">Nova senha</Text>}
                  placeholder="Digite a nova senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  password
                />
              </div>
            </div>
            <Button isLoading={isLoadingRegisterNewPassword} disabled={newPassword.length === 0} onClick={handleRegisterNewPassword} className="primary">
              <Text className="bold">Cadastrar nova senha</Text>
            </Button>
          </div>
        ) :
          sendToken ?
            <div className="Form">
              <div className="d-flex flex-column gap-4">
                <div className="d-flex align-items-center justify-content-center w-100 position-relative">
                  <RiArrowLeftSLine onClick={() => setSendToken(false)} style={{ position: "absolute", left: 0, cursor: "pointer" }} size="24" color={getRootVar("--gray3")} />
                  <Text color="secondary" className="bold" style={{ fontSize: "24px" }}>Insira o token</Text>
                </div>
                <div className="Subtitle">
                  <Text className="smallTxt">Digite o token enviado para o seu e-mail</Text>
                </div>
                <div className="d-flex gap-4 flex-column">
                  <VerificationInput
                    length={6}
                    value={token}
                    onChange={(value) => setToken(value)}
                    onComplete={(value) => handleVerifyToken(value)}
                    validChars="0-9"
                    autoFocus={true}
                    placeholder=""
                    classNames={{
                      container: 'pin-container',
                      character: 'pin-character',
                      characterInactive: 'pin-character--inactive',
                      characterSelected: 'pin-character--selected',
                      characterFilled: 'pin-character--filled'
                    }}
                  />
                </div>
                <div className="Subtitle">
                  {errorVerificationToken === 1 &&
                    <Text className="tinyTxt" color="red" autoTranslate="false">Token inválido. Confira se você digitou o código corretamente. Se o erro persistir, tente reenviar o token para o seu e-mail.</Text>
                  }
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center">
                {resendDelayTime === 0 ?
                  <Text className="smallTxt" color="secondary" onClick={resendVerificationToken}>Enviar novamente</Text> :
                  <Text className="smallTxt" color="gray3" autoTranslate="false">{t('Enviar novamente em {{resendDelayTime}}s...', { resendDelayTime })}</Text>
                }
              </div>
            </div>
            :
            <div className="Form">
              <div className="d-flex flex-column gap-4">
                <div className="d-flex align-items-center justify-content-center w-100 position-relative">
                  <RiArrowLeftSLine onClick={() => navigate("/login")} style={{ position: "absolute", left: 0, cursor: "pointer" }} size="24" color={getRootVar("--gray3")} />
                  <Text color="secondary" className="bold" style={{ fontSize: "24px" }}>Recuperar senha</Text>
                </div>
                <div className="Subtitle">
                  <Text className="smallTxt">Digite seu e-mail para enviarmos o token de recuperação de senha.</Text>
                </div>
                <div className="d-flex gap-4 flex-column">
                  <Input
                    label={<Text className="bold smallTxt">E-mail</Text>}
                    placeholder="Digite o e-mail de recuperação"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    error={sendToken && error}
                  />
                </div>
                {error &&
                  <div className="Subtitle" style={{ marginTop: "-10px" }}>
                    <Text className="tinyTxt" color="red" autoTranslate="false">{error}</Text>
                  </div>
                }
              </div>
              <Button disabled={email.length === 0} onClick={validateEmail} className="primary">
                <Text className="bold">Enviar token</Text>
              </Button>
            </div>
        }
      </div>
      {window.innerWidth <= 980 &&
        <img
          src={largeLogo}
          alt="Phenosync"
          style={{ width: '110px', height: 'auto' }}
        />
      }
    </div >
  )
}

export default RecoverPasswordPage;