import {
  useContext,
  useState,
} from 'react';

import { AuthContext } from 'contexts/auth';
import './styles.scss';
import logo from '../../assets/images/pverde.png'
import largeLogo from '../../assets/images/phenosync.png'
import Text from 'components/Text';
import Input from 'components/Input';
import { onChange } from 'utils/generalFunctions';
import Button from 'components/Button';
import {
  useEffect,
} from 'react';
import { getQueryStringParams } from 'utils/browser';


const LoginPage = () => {
  const { isLoading, isAuthenticated, navigate, loginWithAuthorizationCode } = useContext(AuthContext);
 /*  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const params = getQueryStringParams();
      if (params.code) {
        loginWithAuthorizationCode(params.code);
      } else {
        const redirectUrl = `https://estufa-live.auth.sa-east-1.amazoncognito.com/login
          ?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}
          &response_type=code
          &scope=email+openid+profile
          &redirect_uri=${window.location.href}
        `.replace(/\s/g, "");
        if (localStorage.getItem("DEV")) {
          const logMsg = `redirectUrl: ${redirectUrl}`;
          console.log(logMsg);
          alert(logMsg);
        }
        window.location.href = redirectUrl;
      }
    } else if (isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated, navigate, loginWithAuthorizationCode]);

  return; */
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  function handleLogin() {
    navigate("/")
  }

  const canNotLogin = username.length === 0 || password.length === 0

  return (
    <div className="LoginContainer">
      <div className="Wrapper">
        {window.innerWidth > 980 &&
          <div className="Content gap-4">
            <div className='d-flex gap-4 flex-column'>
              <img
                src={logo}
                alt="Phenosync"
                style={{ width: '70px', height: 'auto' }}
              />
              <Text className="bold" style={{ fontSize: "48px", maxWidth: "300px", lineHeight: "56px" }}>Onde pesquisa e futuro se conectam.</Text>
            </div>
            <img
              src={largeLogo}
              alt="Phenosync"
              style={{ width: '100px', height: 'auto' }}
            />
          </div>
        }
        <div className="Form">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex align-items-center justify-content-center w-100">
              <Text color="secondary" className="bold" style={{ fontSize: "24px" }}>Faça seu login</Text>
            </div>
            <div className="d-flex gap-4 flex-column">
              <Input
                label={<Text className="bold smallTxt">Nome de usuário ou e-mail</Text>}
                placeholder="Digite seu nome ou e-mail"
                value={username}
                onChange={(e) => onChange(e.target.value, setUsername)}
                required
              />
              <div className="d-flex gap-2 flex-column align-items-end">
                <Input
                  label={<Text className="bold smallTxt">Senha</Text>}
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={(e) => onChange(e.target.value, setPassword)}
                  required
                  password
                />
                <Text className="tinyTxt" onClick={() => navigate("/recoverPassword")}>Esqueci minha senha</Text>
              </div>
            </div>
          </div>
          <Button disabled={canNotLogin} onClick={handleLogin} className="primary">
            <Text className="bold">Fazer login</Text>
          </Button>
          <div className="d-flex gap-1 w-100 justify-content-center align-items-center">
            <Text className="smallTxt">Ainda não possui uma conta?</Text>
            <Text color="secondary" className="smallTxt bold" onClick={() => navigate("/register")}>Cadastrar</Text>
          </div>
        </div>
      </div>
      {window.innerWidth <= 980 &&
        <img
          src={largeLogo}
          alt="Phenosync"
          style={{ width: '110px', height: 'auto' }}
        />
      }
    </div>
  )
}

export default LoginPage;