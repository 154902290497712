'use client'
import Text from 'components/Text'
import './styles.scss'
import { useState } from 'react'
import Input from 'components/Input';
import { onChange } from 'utils/generalFunctions';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

export default function CodeGroupsDashboard({
  items,
  saveGroupsList,
  savedGroupList,
  handleSelectedGroups,
  handleState
}) {
  const [isItemSelected, setIsSelectedItem] = useState([]);
  const [isGroupSelected, setIsGroupSelected] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [groupList, setGroupList] = useState(savedGroupList ? savedGroupList : []);
  const { t } = useTranslation();

  function handleItemClick(item) {
    const newSelectedItems = new Set(isItemSelected);

    if (newSelectedItems.has(item)) {
      newSelectedItems.delete(item);
    } else {
      newSelectedItems.add(item);
    }

    setIsSelectedItem(newSelectedItems);
  }

  function handleGroupClick(group) {
    const newSelectedGroups = new Set(isGroupSelected);

    if (newSelectedGroups.has(group)) {
      newSelectedGroups.delete(group);
    } else {
      newSelectedGroups.add(group);
    }

    const updatedSelectedGroups = [...newSelectedGroups];

    setIsGroupSelected(newSelectedGroups);
    handleState(updatedSelectedGroups);
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min); // Arredonda para cima
    max = Math.floor(max); // Arredonda para baixo
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleCreateGroup() {
    setIsSelectedItem([])
    setGroupName("")

    const newItem = { id: getRandomInt(1, 99999), name: groupName, value: [...isItemSelected] }

    setGroupList([newItem, ...groupList])
    saveGroupsList([newItem, ...groupList])
  }

  const handleSearch = (event) => {
    setSearchValue(event.target.value)
  }

  function handleDeleteGroups() {
    const updatedGroupList = groupList.filter(
      (group) => !isGroupSelected.has(group) // Verifica se o ID está fora dos selecionados
    );

    setGroupList(updatedGroupList); // Atualiza a lista
    saveGroupsList(updatedGroupList); // Salva no armazenamento persistente
    setIsGroupSelected(new Set()); // Limpa os grupos selecionados
  }

  const filteredItems = items?.filter(item => item.label.toString().toLowerCase().includes(searchValue.toLowerCase()))
  const deleteGroupsNumber = [...isGroupSelected]?.length;

  return (
    <div className='dashboard'>
      <div className="dashContainer">
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-between align-items-center w-100' style={{ height: "40px" }}>
            <Text className="bold">Códigos</Text>
            <Input
              placeholder="Pesquisar"
              width="130px"
              height="35px"
              value={searchValue}
              onChange={handleSearch}
              style={{ minWidth: "auto" }}
              small
            />
          </div>
          <div className="dashWrapper">
            <div className={`dashboardContent ${filteredItems?.length > 5 ? "overflow" : ""}`}>
              {filteredItems.map((e, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => handleItemClick(e.value)}
                    className={`dashItem ${[...isItemSelected].includes(e.value) ? "selected" : ""}`}
                  >
                    <Text>{e.label}</Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {window.innerWidth > 960 ?
          <div className='d-flex align-items-center gap-3'>
            <Input
              placeholder="Digite o nome do grupo"
              height="40px"
              value={groupName}
              onChange={(e) => onChange(e.target.value, setGroupName)}
              disabled={isItemSelected?.size === 0}
              minWidth="auto"
            />
            <Button disabled={!groupName} className="secondary" onClick={() => handleCreateGroup()}>
              <Text className="smallTxt bold">Criar grupo</Text>
            </Button>
          </div> :
          <div className='d-flex flex-column align-items-center gap-3'>
            <Input
              label={<Text className="bold smallTxt">Nome do grupo</Text>}
              placeholder="Digite o nome do grupo"
              value={groupName}
              onChange={(e) => onChange(e.target.value, setGroupName)}
              disabled={isItemSelected?.size === 0}
              minWidth="auto"
            />
            <Button width="100%" disabled={!groupName} className="secondary" onClick={() => handleCreateGroup()}>
              <Text className="smallTxt bold">Criar grupo</Text>
            </Button>
          </div>}
      </div >
      <div className="dashContainer">
        <div className='d-flex flex-column gap-2'>
          <div className="d-flex w-100 align-items-center justify-content-between" style={{ height: "40px" }}>
            <Text className="bold">Grupos</Text>
            <Text onClick={handleDeleteGroups} color={deleteGroupsNumber > 0 ? "gray2" : "gray4"} className="tinyTxt">{deleteGroupsNumber?.lenght > 1 ? t("Deletar grupos ({{ deleteGroupsNumber }})", { deleteGroupsNumber }) : t("Deletar grupo ({{ deleteGroupsNumber }})", { deleteGroupsNumber })}</Text>
          </div>
          <div className="dashWrapper">
            <div className={`dashboardContent ${groupList?.length > 5 ? "overflow" : ""}`}>
              {groupList.map((e, key) => (
                <div
                  key={key}
                  onClick={() => handleGroupClick(e)}
                  className={`dashItem ${[...isGroupSelected].some(group => group.value === e.value) ? "selected" : ""}`}
                >
                  <Text>{e.name}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Button disabled={isGroupSelected?.length === 0} className="secondary" onClick={() => handleSelectedGroups(isGroupSelected)}>
          <Text className="smallTxt bold white">Comparar grupos</Text>
        </Button>
      </div >
    </div>
  )
}