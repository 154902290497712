'use client'
import { useTranslation } from 'react-i18next'
import './styles.scss'

export default function Text({
  children,
  onClick,
  className,
  color,
  autoTranslate = true,
  ...props
}) {

  const combinedClassName =
    `${className || ''} ${onClick ? 'clickable' : ''}`.trim()

  const { t } = useTranslation()

  return (
    <p
      className={combinedClassName}
      style={{ color: `var(--${color})` }}
      onClick={onClick}
      {...props}>
      {typeof children === "string" && autoTranslate ? t(children) : children}
    </p>
  )
}